import { getEmbedYoutubeUrl } from "~/lib/utils";
import Text from "../ui/text";
import { IImageAttrs, ILinkAttrs, TRenderTypeMap } from "./content-renderer";

export const defaultRenderTypeMap: TRenderTypeMap = {
  content: {
    paragraph: ParagraphNode,
    heading: HeadingNode,
    text: FragmentNode,
    hardBreak: HardBreakNode,
    orderedList: OrderedList,
    bulletList: UnorderedList,
    listItem: ListItem,
    imageItem: ImageNode,
    youtubeItem: YouTubeNode,
  },
  marks: {
    bold: Strong,
    italic: Italic,
    underline: Underline,
    strike: Strike,
    none: FragmentNode,
    link: LinkNode,
  },
};

function LinkNode({
  children,
  attrs,
}: {
  children: React.ReactNode;
  attrs: ILinkAttrs;
}) {
  const isInternal = attrs.href.startsWith("/");
  let url = "";
  // add https to external links if no protocol is provided
  if (
    !isInternal &&
    !attrs.href.startsWith("http") &&
    !attrs.href.startsWith("mailto")
  ) {
    url = `https://${attrs.href}`;
  } else {
    url = attrs.href;
  }

  return (
    <a
      href={url}
      target={isInternal ? "_self" : "_blank"}
      rel="noreferrer"
      className="underline text-primary-foreground"
    >
      {children}
    </a>
  );
}

function YouTubeNode({ attrs }: { attrs: { src: string } }) {
  return (
    <iframe
      src={getEmbedYoutubeUrl(attrs.src)}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      className="w-full aspect-video max-w-[900px]"
      title="YouTube video player"
      allowFullScreen
    />
  );
}
function ImageNode({ attrs }: { attrs: IImageAttrs }) {
  return (
    <img
      src={attrs.src}
      width={attrs.width || "auto"}
      height={attrs.height || "auto"}
      alt=""
    />
  );
}

function HeadingNode({
  level,
  children,
}: {
  level: number;
  children?: React.ReactNode;
}) {
  const levelArray = [
    "text-4xl mt-4 mb-2",
    "text-3xl mt-2 mb-2",
    "text-2xl mt-2 mb-2",
    "text-xl",
    "text-lg",
    "text-md",
    "text-sm",
    "text-xs",
  ];
  return <Text className={levelArray[level - 1]}>{children}</Text>;
}

function HardBreakNode() {
  return <br />;
}
function ParagraphNode({ children }: { children?: React.ReactNode }) {
  return (
    <Text>
      <p>{children}</p>
    </Text>
  );
}
function FragmentNode({ children }: { children?: React.ReactNode }) {
  return <>{children}</>;
}

function Strong({ children }: { children?: React.ReactNode }) {
  return <span className="font-bold">{children}</span>;
}
function Italic({ children }: { children?: React.ReactNode }) {
  return <span className="italic">{children}</span>;
}
function Underline({ children }: { children?: React.ReactNode }) {
  return <u>{children}</u>;
}

function OrderedList({ children }: { children?: React.ReactNode }) {
  return <ol>{children}</ol>;
}

function UnorderedList({ children }: { children?: React.ReactNode }) {
  return <ul>{children}</ul>;
}

function ListItem({ children }: { children?: React.ReactNode }) {
  return <li>{children}</li>;
}

function Strike({ children }: { children?: React.ReactNode }) {
  return <s>{children}</s>;
}
